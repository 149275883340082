import {
    ColumnDef,
    ColumnFilter,
    ColumnSort,
    PaginationState,
    Row as TableRow,
    createColumnHelper,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'
import { ShortDeal } from '../../../../services/api/deal/DealService.types'
import { buildClasses } from '../../../../utils/StyleHelper'
import { formatCurrency, roundedValueToTwoDecimals } from '../../../../utils/Helper'
import { formatDate } from '../../../../utils/DateHelper'
import { useMediaQuery } from 'usehooks-ts'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import Card from '../../../base/card/Card'
import CardActions from '../../../base/card/CardActions'
import CardText from '../../../base/card/CardText'
import Column from '../../../base/grid/Column'
import DemandOrderTableProductFilter from '../../demand-order-shared/table/DemandOrderTableProductFilter'
import DemandOrderTableProductNameCell from '../../demand-order-shared/table/DemandOrderTableProductNameCell'
import Link from 'next/link'
import OrderStatusBadge from '../detail/OrderStatusBadge'
import OrderTableActionCell from './OrderTableActionCell'
import OrderTableStatusFilter from './OrderTableStatusFilter'
import React, { ReactNode, useMemo, useState } from 'react'
import Row from '../../../base/grid/Row'
import Table from '../../../base/table/Table'
import TablePagination from '../../../base/table/TablePagination'
import useDealPage from '../../../../hooks/api/deal/useDealPage'
import useTheme from '../../../../hooks/useTheme'

export type OrderTableProps = {
    pagination?: boolean
    filtering?: boolean
    sorting?: boolean
    initialSortBy?: ColumnSort[]
    onClickRow?: (order: TableRow<ShortDeal>) => void
    renderCustomActions?: (order: TableRow<ShortDeal>) => React.ReactNode
    height?: number
    showShowAllAction?: boolean
    rounded?: boolean
    stickyPagination?: boolean
    sticky?: boolean
    initialFilters?: ColumnFilter[]
    disabledFilters?: string[]
    customEmpty?: ReactNode
    hasHeader?: boolean
    pageSize?: number
    dashboard?: boolean
    hasFiltersRow?: boolean
}

const OrderTable = ({
    pagination: enabledPagination = true,
    filtering: enabledFiltering = true,
    sorting: enabledSorting = true,
    height,
    initialSortBy = [],
    onClickRow,
    renderCustomActions,
    showShowAllAction,
    rounded = true,
    sticky,
    stickyPagination,
    disabledFilters = [],
    initialFilters = [],
    customEmpty,
    hasHeader = true,
    pageSize = 15,
    dashboard = false,
    hasFiltersRow = true
}: OrderTableProps) => {
    const { t } = useTranslation()
    const { dateFormat } = useTheme()
    const isTablet = useMediaQuery('(max-width: 1150px)')
    const [filters, setFilters] = useState<ColumnFilter[]>(initialFilters)
    const [sorts, setSorts] = useState<ColumnSort[]>(initialSortBy)
    const [pagination, setPagination] = useState<PaginationState>({ pageSize, pageIndex: 0 })
    const { data: { data: orders = [], meta } = {}, isLoading } = useDealPage({ filters, sorts, pagination })

    const columns = useMemo<ColumnDef<ShortDeal>[]>(() => {
        const columnHelper = createColumnHelper<ShortDeal>()
        const columns = []

        {
            dashboard
                ? columns.push(
                      ...[
                          columnHelper.accessor('ts', {
                              size: 90,
                              header: t<string>('frontend.dashboard.order.table.creation_date'),
                              cell: context => {
                                  return formatDate(context.getValue(), dateFormat)
                              },
                              enableColumnFilter: !disabledFilters.includes('ts'),
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          columnHelper.accessor('number', {
                              size: 90,
                              header: t<string>('frontend.dashboard.order.table.number'),
                              enableColumnFilter: !disabledFilters.includes('number')
                          }),
                          columnHelper.accessor('productName', {
                              header: t<string>('frontend.dashboard.order.table.product'),
                              cell: context => {
                                  return <DemandOrderTableProductNameCell value={context.getValue()} />
                              },
                              enableSorting: false,
                              enableColumnFilter: !disabledFilters.includes('productName'),
                              meta: {
                                  filtering: props => {
                                      return <DemandOrderTableProductFilter {...props} />
                                  }
                              }
                          }),
                          columnHelper.accessor('price', {
                              size: 109,
                              header: t<string>('frontend.dashboard.order.table.price'),
                              cell: context => {
                                  if (context.row.original.currency) {
                                      return formatCurrency(
                                          roundedValueToTwoDecimals(context.getValue()),
                                          context.row.original.currency
                                      )
                                  }
                                  return '-'
                              },
                              enableColumnFilter: !disabledFilters.includes('price'),
                              filterFn: 'equals',
                              meta: {
                                  filtering: 'number'
                              }
                          }),
                          columnHelper.accessor('amount', {
                              size: 68,
                              header: t<string>('frontend.dashboard.order.table.number_of_pieces'),
                              cell: context => {
                                  return `${context.getValue()} ks`
                              },
                              enableSorting: false,
                              enableColumnFilter: false
                          }),
                          columnHelper.accessor('status', {
                              header: t<string>('frontend.dashboard.order.table.status'),
                              cell: context => {
                                  return (
                                      <div className='flex justify-end w-full'>
                                          <OrderStatusBadge status={context.getValue()} />
                                      </div>
                                  )
                              },
                              enableColumnFilter: !disabledFilters.includes('status'),
                              enableSorting: false,
                              meta: {
                                  filtering: props => {
                                      return <OrderTableStatusFilter {...props} />
                                  }
                              }
                          })
                      ]
                  )
                : columns.push(
                      ...[
                          columnHelper.accessor(row => row.number, {
                              size: isTablet ? undefined : 90,
                              id: 'number',
                              header: t<string>('frontend.dashboard.order.table.number'),
                              cell: context => {
                                  const { customLabel, number } = context.row.original
                                  return (
                                      <>
                                          {customLabel && <div className='font-bold'>{customLabel}</div>}
                                          <div>{number}</div>
                                      </>
                                  )
                              },
                              enableColumnFilter:
                                  !disabledFilters.includes('customLabel') && !disabledFilters.includes('number'),
                              meta: {
                                  filterTooltip: t('frontend.dashboard.demand.table.tooltip')
                              }
                          }),
                          columnHelper.accessor('productName', {
                              header: t<string>('frontend.dashboard.order.table.product'),
                              cell: context => {
                                  return <DemandOrderTableProductNameCell value={context.getValue()} />
                              },
                              enableSorting: false,
                              enableColumnFilter: !disabledFilters.includes('productName'),
                              meta: {
                                  filtering: props => {
                                      return <DemandOrderTableProductFilter {...props} />
                                  }
                              }
                          }),
                          columnHelper.accessor('price', {
                              size: isTablet ? undefined : 100,
                              header: t<string>('frontend.dashboard.order.table.price'),
                              cell: context => {
                                  if (context.row.original.currency) {
                                      return formatCurrency(
                                          roundedValueToTwoDecimals(context.getValue()),
                                          context.row.original.currency
                                      )
                                  }
                                  return '-'
                              },
                              enableColumnFilter: !disabledFilters.includes('price'),
                              filterFn: 'equals',
                              meta: {
                                  filtering: 'number'
                              }
                          }),
                          columnHelper.accessor('ts', {
                              size: 90,
                              header: t<string>('frontend.dashboard.order.table.creation_date'),
                              cell: context => {
                                  return formatDate(context.getValue(), dateFormat)
                              },
                              enableColumnFilter: !disabledFilters.includes('ts'),
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          columnHelper.accessor('expeditionDate', {
                              size: 90,
                              header: t<string>('frontend.dashboard.order.table.date_of_delivery'),
                              cell: context => {
                                  return formatDate(context.getValue(), dateFormat)
                              },
                              enableColumnFilter: !disabledFilters.includes('expeditionDate'),
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          //implementation in next phase
                          //   columnHelper.accessor('amount', {
                          //       size: 68,
                          //       header: t<string>('frontend.dashboard.order.table.number_of_pieces'),
                          //       cell: context => {
                          //           return `${context.getValue()} ks`
                          //       },
                          //       enableSorting: false,
                          //       enableColumnFilter: false
                          //   }),
                          columnHelper.accessor('status', {
                              size: isTablet ? undefined : 110,
                              header: t<string>('frontend.dashboard.order.table.status'),
                              cell: context => {
                                  return <OrderStatusBadge status={context.getValue()} />
                              },
                              enableColumnFilter: !disabledFilters.includes('status'),
                              enableSorting: false,
                              meta: {
                                  filtering: props => {
                                      return <OrderTableStatusFilter {...props} />
                                  }
                              }
                          }),
                          columnHelper.display({
                              size: isTablet ? undefined : 70,
                              header: t<string>('frontend.dashboard.order.table.action'),
                              cell: context => {
                                  return renderCustomActions ? (
                                      renderCustomActions(context.row)
                                  ) : (
                                      <OrderTableActionCell row={context.row} />
                                  )
                              },
                              enableSorting: false
                          })
                      ]
                  )
        }
        return columns
    }, [dateFormat, disabledFilters, renderCustomActions, dashboard, t])

    const table = useReactTable<ShortDeal>({
        columns,
        data: orders,
        getCoreRowModel: getCoreRowModel(),
        pageCount: meta?.lastPage,
        state: {
            pagination,
            columnFilters: filters,
            sorting: sorts
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setFilters,
        onSortingChange: setSorts,
        enableFilters: enabledFiltering,
        enableSorting: enabledSorting,
        manualSorting: true,
        manualFiltering: true,
        manualPagination: true
    })

    const isNotEmpty = orders.length > 0

    return (
        <Card shadow rounded={rounded}>
            <CardText removePaddingX removePaddingY>
                <Table
                    isRoundedRow={dashboard}
                    hasHeader={hasHeader}
                    instance={table}
                    onClickRow={onClickRow}
                    loading={isLoading}
                    maxHeight={height}
                    sticky={sticky}
                    customEmpty={customEmpty}
                    striped
                    hasFiltersRow={hasFiltersRow}
                />
                {!isLoading && enabledPagination && (
                    <div className={buildClasses({ 'sticky bottom-0 left-0': stickyPagination })}>
                        <TablePagination
                            table={table}
                            totalItems={meta?.total || 0}
                            itemsPerPage={meta?.perPage || 0}
                        />
                    </div>
                )}
            </CardText>
            {showShowAllAction && isNotEmpty && (
                <CardActions>
                    <Row justify='start'>
                        <Column>
                            <Button
                                as={Link}
                                href='/app/order'
                                variant='outlined'
                                color='secondary'
                                className='h-[41px] ml-1.5'
                            >
                                {t('frontend.dashboard.order.table.actions.show_all')}
                            </Button>
                        </Column>
                    </Row>
                </CardActions>
            )}
        </Card>
    )
}

export default OrderTable
